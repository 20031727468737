import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import MetaTags from "react-meta-tags";
import "./Contact.scss";

const Description = `
  Get in touch with Hitronix.
`;

const AlertSuccessMessage = `
  Hi [NAME],<br><br>
  Thanks for contacting us.<br>
  We received your message and assigned reference [ID].<br>
  You should see a confirmation in your inbox.<br>
  We will reply as soon as possible.<br><br>
  Best regards,<br>
  Hitronix
`;

const AlertErrorMessage = `
  Hi [NAME],<br><br>
  Unfortunately, the message could not be sent.<br>
  Please write us at contact@hitronix.tech.<br>
  Sorry for the inconvenience.<br><br>
  Best regards,<br>
  Hitronix
`;

interface Props {}

interface State {
  buttonDisabled: boolean;
  buttonText: string;
  alertVariant: string;
  alertVisible: boolean;
  alertMessage: string;
  spinner: boolean;
}

class Contact extends React.Component<Props, State> {
  public name = "";
  public email = "";
  public message = "";
  public reCaptchaValue = "";
  public reCaptchaRef = React.createRef<ReCAPTCHA>();
  constructor(props: Props) {
    super(props);
    this.state = {
      buttonDisabled: true,
      buttonText: "Submit",
      alertVariant: "success",
      alertVisible: false,
      alertMessage: "",
      spinner: false,
    };
  }
  public validate() {
    let disabled = false;
    if (this.reCaptchaValue === "") {
      disabled = true;
    }
    if (this.name === "") {
      disabled = true;
    }
    if (this.email === "") {
      disabled = true;
    }
    if (this.message === "") {
      disabled = true;
    }
    this.setState({ buttonDisabled: disabled });
  }
  public onCloseAlert() {
    this.setState({ alertVisible: false });
  }
  public onNameChange(value: string) {
    this.name = value;
    this.validate();
  }
  public onEmailChange(value: string) {
    this.email = value;
    this.validate();
  }
  public onMessageChange(value: string) {
    this.message = value;
    this.validate();
  }
  public onChange(value: string | null) {
    if (value !== null || value !== "" || value !== undefined) {
      this.reCaptchaValue = String(value);
    } else {
      this.reCaptchaValue = "";
    }
    this.validate();
  }
  public onErrored() {
    this.reCaptchaValue = "";
    if (this.reCaptchaRef.current !== null) {
      this.reCaptchaRef.current.reset();
    }
    this.validate();
  }
  public onExpired() {
    this.reCaptchaValue = "";
    if (this.reCaptchaRef.current !== null) {
      this.reCaptchaRef.current.reset();
    }
    this.validate();
  }
  public onSubmit(event: React.FormEvent<HTMLFormElement>) {
    console.log("Submit: " + event);
    this.setState({
      spinner: true,
      buttonText: "Submiting",
      buttonDisabled: true,
    });
    let data = {
      name: this.name,
      email: this.email,
      message: this.message,
      token: this.reCaptchaValue,
    };
    axios
      .post("https://hitronix.tech/api/contact", data)
      .then((res) => {
        const status = Number(res.data.status);
        if (status === 0) {
          this.setState({
            alertVariant: "success",
            alertVisible: true,
            alertMessage: AlertSuccessMessage.replace(
              "[NAME]",
              String(this.name).trimEnd()
            ).replace("[ID]", String(res.data.id).trimEnd()),
          });
          console.log("Contact success");
        } else {
          this.setState({
            alertVariant: "danger",
            alertVisible: true,
            alertMessage: AlertErrorMessage.replace(
              "[NAME]",
              String(this.name).trimEnd()
            ),
          });
          console.log(
            "Contact error status: " +
              status +
              " message: " +
              String(res.data.message).trimEnd()
          );
        }
        this.setState({
          spinner: false,
          buttonText: "Submit",
        });
      })
      .catch(() => {
        this.setState({
          alertVariant: "danger",
          alertVisible: true,
          alertMessage: AlertErrorMessage.replace(
            "[NAME]",
            String(this.name).trimEnd()
          ),
        });
        console.log("Contact error");
        this.setState({
          spinner: false,
          buttonText: "Submit",
        });
      });
    if (this.reCaptchaRef.current !== null) {
      this.reCaptchaRef.current.reset();
    }
  }
  render() {
    return (
      <div className="wrapper">
        <MetaTags>
          <title>Hitronix - Contact</title>
          <meta name="description" content={Description} />
        </MetaTags>
        <Container fluid className="p-0 m-0">
          <Row className="Contact-Space p-0 m-0"></Row>
          <Row className="p-0 m-0">
            <Col className="d-none d-xl-block"></Col>
            <Col xl={9}>
              <p className="Contact-Title">Get in Touch</p>
              <Form
                onSubmit={(event) => {
                  event.preventDefault();
                  this.onSubmit(event);
                }}
              >
                <Form.Group controlId="formName">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => this.onNameChange(event.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => this.onEmailChange(event.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formMessage">
                  <Form.Control
                    as="textarea"
                    rows={10}
                    placeholder="Message"
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>
                    ): void => this.onMessageChange(event.target.value)}
                  />
                </Form.Group>
                <div className="Contact-ReCaptcha">
                  <ReCAPTCHA
                    ref={this.reCaptchaRef}
                    sitekey="6LdUC34aAAAAAFY3huv0farG3r5Od0jX0FXudrRp"
                    onChange={(value) => this.onChange(value)}
                    onErrored={() => this.onErrored()}
                    onExpired={() => this.onExpired()}
                  />
                </div>
                <div className="d-flex flex-row align-items-center align-self-center">
                  <div className="p-0 m-0">
                    <Button
                      disabled={this.state.buttonDisabled}
                      variant="primary"
                      type="submit"
                    >
                      {this.state.buttonText}
                    </Button>
                  </div>
                  {this.state.spinner && (
                    <div className="p-0 ml-2">
                      <Spinner animation="border" />
                    </div>
                  )}
                </div>
              </Form>
              <div className="Contact-Alert">
                <Alert
                  show={this.state.alertVisible}
                  variant={this.state.alertVariant}
                  dismissible
                  onClose={() => this.onCloseAlert()}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.alertMessage,
                    }}
                  ></div>
                </Alert>
              </div>
              <div className="Contact-Email">
                <a
                  className="Contact-Email-Link"
                  href="mailto:contact@hitronix.tech"
                >
                  Or write us at contact@hitronix.tech
                </a>
              </div>
            </Col>
            <Col className="d-none d-xl-block"></Col>
          </Row>
          <Row className="Contact-Space p-0 m-0"></Row>
        </Container>
      </div>
    );
  }
}

export default Contact;
