import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "./Services.scss";

const LogosSoftware = [
  {
    name: "angular",
    alt: "Angular",
    file: "angular.png",
  },
  {
    name: "react",
    alt: "React",
    file: "react.png",
  },
  {
    name: "vue",
    alt: "Vue",
    file: "vue.png",
  },
  {
    name: "electron",
    alt: "Electron",
    file: "electron.png",
  },
  {
    name: "ionic",
    alt: "Ionic",
    file: "ionic.png",
  },
  {
    name: "nodejs",
    alt: "Node JS",
    file: "nodejs.png",
  },
  {
    name: "apache",
    alt: "Apache",
    file: "apache.png",
  },
  {
    name: "mysql",
    alt: "MySQL",
    file: "mysql.png",
  },
  {
    name: "mongodb",
    alt: "Mongo DB",
    file: "mongodb.png",
  },
  {
    name: "graphdb",
    alt: "Graph DB",
    file: "graphdb.png",
  },
  {
    name: "aws",
    alt: "AWS",
    file: "aws.png",
  },
  {
    name: "gcp",
    alt: "Google Cloud Platform",
    file: "gcp.png",
  },
  {
    name: "firebase",
    alt: "Firebase",
    file: "firebase.png",
  },
  {
    name: "msazure",
    alt: "Microsoft Azure",
    file: "msazure.png",
  },
  {
    name: "msnet",
    alt: "Microsoft .NET",
    file: "msnet.png",
  },
  {
    name: "csharp",
    alt: "Microsoft C#",
    file: "csharp.png",
  },
  {
    name: "vscode",
    alt: "Microsof Visual Studio Code",
    file: "vscode.svg",
  },
  {
    name: "git",
    alt: "Git",
    file: "git.png",
  },
  {
    name: "gitlab",
    alt: "GitLab",
    file: "gitlab.png",
  },
];

const LogosFirmware = [
  {
    name: "embeddedlinux",
    alt: "Embedded Linux",
    file: "embeddedlinux.png",
  },
  {
    name: "buildroot",
    alt: "Buildroot",
    file: "buildroot.png",
  },
  {
    name: "yocto",
    alt: "Yocto",
    file: "yocto.png",
  },
  {
    name: "uboot",
    alt: "U-Boot",
    file: "uboot.png",
  },
  {
    name: "freertos",
    alt: "FreeRTOS",
    file: "freertos.png",
  },
  {
    name: "canopen",
    alt: "CANOpen",
    file: "canopen.png",
  },
  {
    name: "ethercat",
    alt: "EtherCAT",
    file: "ethercat.png",
  },
  {
    name: "modbus",
    alt: "Modbus",
    file: "modbus.png",
  },
  {
    name: "profibus",
    alt: "Profibus",
    file: "profibus.png",
  },
  {
    name: "usb",
    alt: "USB",
    file: "usb.png",
  },
  {
    name: "wifi",
    alt: "wifi",
    file: "wifi.png",
  },
  {
    name: "bluetooth",
    alt: "Bluetooth",
    file: "bluetooth.png",
  },
  {
    name: "lxi",
    alt: "LXI",
    file: "lxi.png",
  },
  {
    name: "ethernet",
    alt: "Ethernet",
    file: "ethernet.svg",
  },
  {
    name: "hdmi",
    alt: "HDMI",
    file: "hdmi.png",
  },
];

const LogosHardware = [
  {
    name: "ti",
    alt: "Texas Instruments",
    file: "ti.png",
  },
  {
    name: "nxp",
    alt: "NXP",
    file: "nxp.png",
  },
  {
    name: "atmel",
    alt: "Atmel",
    file: "atmel.png",
  },
  {
    name: "arm",
    alt: "ARM",
    file: "arm.png",
  },
  {
    name: "xiling",
    alt: "Xiling",
    file: "xiling.png",
  },
  {
    name: "intel",
    alt: "Intel",
    file: "intel.png",
  },
  {
    name: "microchip",
    alt: "Microchip",
    file: "microchip.png",
  },
  {
    name: "micron",
    alt: "Micron",
    file: "micron.png",
  },
  {
    name: "analogdevices",
    alt: "Analog Devices",
    file: "analogdevices.svg",
  },
  {
    name: "infineon",
    alt: "Infineon",
    file: "infineon.png",
  },
  {
    name: "onsemi",
    alt: "ON Semiconductors",
    file: "onsemi.jpg",
  },
  {
    name: "st",
    alt: "ST Semiconductors",
    file: "st.png",
  },
  {
    name: "beaglebone",
    alt: "Beaglebone",
    file: "beaglebone.png",
  },
  {
    name: "raspberry",
    alt: "Raspberry",
    file: "raspberry.png",
  },
  {
    name: "arduino",
    alt: "Arduino",
    file: "arduino.png",
  },
  {
    name: "altium",
    alt: "Altium Designer",
    file: "altium.png",
  },
  {
    name: "kicad",
    alt: "KiCAD",
    file: "kicad.png",
  },
];

function Services() {
  return (
    <Container fluid className="p-0 m-0">
      <Row className="Services-Space p-0 m-0"></Row>
      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col xl={9}>
          <p className="Services-Title">Services</p>
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
      <Row className="pb-4 m-0 justify-content-center align-items-center">
        <Col className="d-none d-xl-block"></Col>
        <Col className="justify-content-center align-items-center" xl={9}>
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <div className="pl-4 pr-4 pb-4 m-0">
              <Image
                src="ilustrations/undraw_progressive_app.svg"
                alt="Programming"
                className="Services-Ilustration w-100"
              />
            </div>
            <div className="pl-4 pr-4">
              <p className="Services-Subtitle">Software</p>
              <ListGroup variant="flush">
                <ListGroup.Item>Front-end development</ListGroup.Item>
                <ListGroup.Item>Back-end development</ListGroup.Item>
                <ListGroup.Item>IoT Integration</ListGroup.Item>
                <ListGroup.Item>Mobile Apps</ListGroup.Item>
                <ListGroup.Item>Desktop Software</ListGroup.Item>
                <ListGroup.Item>Testing Software</ListGroup.Item>
                <ListGroup.Item>UX/UI Design</ListGroup.Item>
                <ListGroup.Item>DevOps</ListGroup.Item>
                <ListGroup.Item>CI/CD Integration</ListGroup.Item>
              </ListGroup>
            </div>
          </div>
          <Container fluid className="p-0 m-0">
            <Row className="p-0 m-0">
              <Col></Col>
              <Col xl={9}>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="1"
                        className="Services-Accordion-Title"
                      >
                        Technologies
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                          {LogosSoftware.map(function (item) {
                            return (
                              <div className="p-3">
                                <Image
                                  src={"logos/" + item.file}
                                  alt={item.alt}
                                  className="Services-Logos"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
      <Row className="pt-4 pb-4 m-0 justify-content-center align-items-center Services-Gray-Background">
        <Col className="d-none d-xl-block"></Col>
        <Col className="justify-content-center align-items-center" xl={9}>
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <div className="pr-4 pl-4">
              <p className="Services-Subtitle">Firmware</p>
              <ListGroup variant="flush">
                <ListGroup.Item className="Services-Gray-Background">
                  Low Level Programming (Assembly and C/C++)
                </ListGroup.Item>
                <ListGroup.Item>Digital Signal Processor</ListGroup.Item>
                <ListGroup.Item>Control Loops and Algorithms</ListGroup.Item>
                <ListGroup.Item>
                  Peripheral Programming (RS232, CAN, USB, Ethernet, I2C, SPI,
                  etc)
                </ListGroup.Item>
                <ListGroup.Item>Real-time Operating Systems</ListGroup.Item>
                <ListGroup.Item>Embedded Linux</ListGroup.Item>
                <ListGroup.Item>Device Drivers</ListGroup.Item>
                <ListGroup.Item>Libraries Integration</ListGroup.Item>
                <ListGroup.Item>Bootloaders</ListGroup.Item>
              </ListGroup>
            </div>
            <div className="pr-4 pl-4">
              <Image
                src="ilustrations/undraw_firmware.svg"
                alt="Firmware"
                className="Services-Ilustration w-100"
              />
            </div>
          </div>
          <Container fluid className="p-0 m-0">
            <Row className="pt-4 m-0">
              <Col></Col>
              <Col xl={9}>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        Technologies
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                          {LogosFirmware.map(function (item) {
                            return (
                              <div className="p-3">
                                <Image
                                  src={"logos/" + item.file}
                                  alt={item.alt}
                                  className="Services-Logos"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
      <Row className="pb-2 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="justify-content-center align-items-center" xl={9}>
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <div className="p-4">
              <Image
                src="ilustrations/undraw_circuit_board.svg"
                alt="Firmware"
                className="Services-Ilustration w-100"
              />
            </div>
            <div className="p-4">
              <p className="Services-Subtitle">Hardware</p>
              <ListGroup variant="flush">
                <ListGroup.Item>System Architecture Design</ListGroup.Item>
                <ListGroup.Item>
                  Microprocessor/DSP/Memory/FPGA Integration
                </ListGroup.Item>
                <ListGroup.Item>
                  Analog and Digital Circuit Design
                </ListGroup.Item>
                <ListGroup.Item>
                  Interfaces (High-speed, Wireless, etc)
                </ListGroup.Item>
                <ListGroup.Item>Supplies (Linear and Switching)</ListGroup.Item>
                <ListGroup.Item>Sensors Integration</ListGroup.Item>
                <ListGroup.Item>Schematic Design</ListGroup.Item>
                <ListGroup.Item>PCB Layout</ListGroup.Item>
                <ListGroup.Item>Prototyping</ListGroup.Item>
                <ListGroup.Item>Testing</ListGroup.Item>
                <ListGroup.Item>Test Fixture Design</ListGroup.Item>
              </ListGroup>
            </div>
          </div>
          <Container fluid className="p-0 m-0">
            <Row className="p-0 m-0">
              <Col></Col>
              <Col xl={9}>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        Technologies
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                          {LogosHardware.map(function (item) {
                            return (
                              <div className="p-3">
                                <Image
                                  src={"logos/" + item.file}
                                  alt={item.alt}
                                  className="Services-Logos"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
      <Row className="Services-Space p-0 m-0"></Row>
    </Container>
  );
}

export default Services;
