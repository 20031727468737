import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./Header.scss";

function Header() {
  return (
    <Navbar className="Header-Navbar" bg="light" expand="lg" fixed="top">
      <Navbar.Brand className="Header-Navbar-Brand" href="/">
        <Container fluid className="p-0 m-0">
          <Row className="align-items-center p-0 m-0">
            <Col className="p-0 m-0">
              <Image
                className="Header-Navbar-Brand-Image"
                src="apple-touch-icon.png"
                alt="Logo"
              />
            </Col>
            <Col className="Header-Navbar-Brand-Text p-0 ml-2">Hitronix</Col>
          </Row>
        </Container>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="Header-Navbar-Collapse justify-content-end">
        <Nav>
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/services">Services</Nav.Link>
          <Nav.Link href="/contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
