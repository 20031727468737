import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Services from "./components/Services";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import ReturnPolicy from "./components/ReturnPolicy";
import Disclaimer from "./components/Disclaimer";
import CookiePolicy from "./components/CookiePolicy";
import "./App.scss";

function App() {
  return (
    <Router>
      <header className="App-Header">
        <Header />
      </header>
      <main className="App-Main">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/services">
            <Services />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route exact path="/return-policy">
            <ReturnPolicy />
          </Route>
          <Route exact path="/disclaimer">
            <Disclaimer />
          </Route>
          <Route exact path="/cookie-policy">
            <CookiePolicy />
          </Route>
          <Route exact path="*">
            <Home />
          </Route>
        </Switch>
      </main>
      <footer className="App-Footer">
        <Footer />
      </footer>
    </Router>
  );
}

export default App;
