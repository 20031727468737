import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaLinkedin, FaCopyright, FaGithub } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import "./Footer.scss";
import React from "react";

function Footer() {
  function onClickCookieSettings() {
    // eslint-disable-next-line no-eval
    eval("displayPreferenceModal();");
  }
  return (
    <Container fluid className="p-2 m-0 Footer-Container">
      <Row className="p-0 m-0">
        <Col className="p-0 m-0"></Col>
        <Col className="p-2 m-0" xs="auto">
          <a
            className="Footer-Link"
            href="https://www.linkedin.com/company/hitronix-tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size="2em" />
          </a>
        </Col>
        <Col className="p-2 m-0" xs="auto">
          <a className="Footer-Link" href="mailto:contact@hitronix.tech">
            <FiMail size="2em" />
          </a>
        </Col>
        <Col className="p-2 m-0" xs="auto">
          <a
            className="Footer-Link"
            href="https://github.com/hitronix"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub size="2em" />
          </a>
        </Col>
        <Col className="p-0 m-0"></Col>
      </Row>
      <Row className="pt-2 m-0">
        <Col className="p-0 m-0"></Col>
        <Col className="p-0 m-0" xs="auto">
          <div className="d-flex flex-wrap align-items-center align-self-center justify-content-center">
            <div className="p-0 m-0">
              <div className="d-flex flex-wrap align-items-center align-self-center justify-content-center">
                <div className="pr-3 m-0">
                  <a className="Footer-Link" href="/privacy-policy">
                    Privacy Policy
                  </a>
                </div>
                <div className="pr-3 m-0">
                  <a className="Footer-Link" href="/terms-and-conditions">
                    Terms and Conditions
                  </a>
                </div>
              </div>
            </div>
            <div className="p-0 m-0">
              <div className="d-flex flex-wrap align-items-center align-self-center justify-content-center">
                <div className="pr-3 m-0">
                  <a className="Footer-Link" href="/return-policy">
                    Return Policy
                  </a>
                </div>
                <div className="pr-3 m-0">
                  <a className="Footer-Link" href="/disclaimer">
                    Disclaimer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col className="p-0 m-0"></Col>
      </Row>
      <Row className="p-0 m-0">
        <Col className="p-0 m-0"></Col>
        <Col className="p-0 m-0" xs="auto">
          <div className="d-flex flex-wrap align-items-center align-self-center justify-content-center">
            <div className="p-0 m-0">
              <div className="d-flex flex-wrap align-items-center align-self-center justify-content-center">
                <div className="pr-3 m-0">
                  <a className="Footer-Link" href="/cookie-policy">
                    Cookie Policy
                  </a>
                </div>
                <div className="pr-3 m-0">
                  <div
                    className="Cookie-Preferences"
                    onClick={onClickCookieSettings}
                  >
                    Cookie Preferences
                  </div>
                </div>
                <div className="p-0 m-0">
                  <a
                    className="Footer-Link"
                    href="https://app.termly.io/notify/964ad1e3-aacb-402b-adbf-641dfeea334b"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Do not sell my info
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col className="p-0 m-0"></Col>
      </Row>
      <Row className="pt-3 pb-3 m-0">
        <Col className="p-0 m-0"></Col>
        <Col className="p-0 m-0" xs="auto">
          <div className="d-flex flex-wrap align-items-center align-self-center justify-content-center">
            <div className="pr-2 m-0">Copyright</div>
            <div className="pr-2 m-0">
              <FaCopyright size="20px" />
            </div>
            <div className="pr-2 m-0">2020-2022 Hitronix LLC.</div>
            <div className="p-0 m-0">All Rights Reserved</div>
          </div>
        </Col>
        <Col className="p-0 m-0"></Col>
      </Row>
    </Container>
  );
}

export default Footer;
