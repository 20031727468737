import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import MetaTags from "react-meta-tags";
import "./Home.scss";

const Description = `
  Full-stack embedded systems consulting services and solutions.
`;

const What = `
  We offer full-stack embedded systems services and solutions, including hardware, firmware, and software.<br>
  If you are looking for a new design or to add value to existing products, we can help.
`;

const How = `
  We apply lean startup, design thinking, and agile development methodologies.<br>
  This combination allows us to provide solutions with a short time to market, dynamic adaptation, and high quality.
`;

const Why = `
  We are passionate about embedded systems and technology trends.<br>
  We love to add value and improve the usability of products and services.
`;

function Home() {
  return (
    <div className="wrapper">
      <MetaTags>
        <title>Hitronix</title>
        <meta name="description" content={Description} />
      </MetaTags>
      <Container fluid className="p-0 m-0">
        <Row className="Home-Image-Container align-items-center p-0 m-0">
          <Image
            className="Home-Image w-100"
            src="images/home.png"
            alt="Embedded system boards"
          />
          <div className="Home-Image-Filter"></div>
          <div className="Home-Image-Text">
            <p className="Home-Image-Text-Title">Embedded Systems</p>
            <p className="Home-Image-Text-Subtitle">Services and solutions</p>
          </div>
        </Row>
        <Row className="Home-Content-What-Title p-2 m-0">
          <Col></Col>
          <Col xs="auto">What we offer</Col>
          <Col></Col>
        </Row>
        <Row className="Home-Content-What pb-4 m-0">
          <Col></Col>
          <Col xs="auto">
            <div
              className="Home-Content-Text"
              dangerouslySetInnerHTML={{ __html: What }}
            ></div>
          </Col>
          <Col></Col>
        </Row>
        <Row className="Home-Content-What pb-4 m-0">
          <Col></Col>
          <Col xs="auto">
            <Image
              src="ilustrations/undraw_programming.svg"
              alt="Programming"
              className="Home-Ilustration w-100"
            />
          </Col>
          <Col></Col>
        </Row>
        <Row className="Home-Content-How-Title p-2 m-0">
          <Col></Col>
          <Col xs="auto">How we work</Col>
          <Col></Col>
        </Row>
        <Row className="Home-Content-How pb-3 m-0">
          <Col></Col>
          <Col xs="auto">
            <div
              className="Home-Content-Text"
              dangerouslySetInnerHTML={{ __html: How }}
            ></div>
          </Col>
          <Col></Col>
        </Row>
        <Row className="Home-Content-How pb-4 m-0">
          <Col></Col>
          <Col xs="auto">
            <Image
              src="ilustrations/undraw_product_iteration.svg"
              alt="Programming"
              className="Home-Ilustration Home-Image-Mirror w-100"
            />
          </Col>
          <Col></Col>
        </Row>
        <Row className="Home-Content-Why-Title p-2 m-0">
          <Col></Col>
          <Col xs="auto">Why</Col>
          <Col></Col>
        </Row>
        <Row className="Home-Content-Why pb-3 m-0">
          <Col></Col>
          <Col xs="auto">
            <div
              className="Home-Content-Text"
              dangerouslySetInnerHTML={{ __html: Why }}
            ></div>
          </Col>
          <Col></Col>
        </Row>
        <Row className="Home-Content-Why pb-4 m-0">
          <Col></Col>
          <Col xs="auto">
            <Image
              src="ilustrations/undraw_success_factors.svg"
              alt="Programming"
              className="Home-Ilustration w-100"
            />
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
