import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Disclaimer.scss";

class Disclaimer extends Component {
  public divTermlyContainer = React.createRef<HTMLDivElement>();
  public divTermly = React.createRef<HTMLDivElement>();
  componentDidMount() {
    if (this.divTermly.current !== null) {
      this.divTermly.current.setAttribute("name", "termly-embed");
    }
    const script = document.createElement("script");
    script.async = true;
    script.id = "termly-jssdk";
    script.src = "https://app.termly.io/embed-policy.min.js";
    if (this.divTermlyContainer.current !== null) {
      this.divTermlyContainer.current.appendChild(script);
    }
  }
  render() {
    return (
      <Container fluid className="p-0 m-0">
        <Row className="p-0 m-0">
          <Col className="d-none d-xl-block"></Col>
          <Col xl={9}>
            <div ref={this.divTermlyContainer}>
              <div
                ref={this.divTermly}
                data-id="577af7e5-ef7f-4554-a6bf-cdbaade8ee62"
                data-type="iframe"
              ></div>
            </div>
          </Col>
          <Col className="d-none d-xl-block"></Col>
        </Row>
      </Container>
    );
  }
}

export default Disclaimer;
